<template>
  <div class="pagination">
    <svg
      v-if="currentPage !== 1"
      @click="changePage(currentPage - 1)"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      class="link"
    >
      <path
        d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
      />
    </svg>
    <span v-if="currentPage > 2" @click="changePage(1)" class="link">1</span>
    <span v-if="currentPage > 3">
      ...
    </span>
    <span
      v-if="currentPage !== 1"
      @click="changePage(currentPage - 1)"
      class="link"
      >{{ currentPage - 1 }}</span
    >
    <span class="link current">{{ currentPage }}</span>
    <span
      v-if="hasNextPage"
      @click="changePage(currentPage + 1)"
      class="link"
      >{{ currentPage + 1 }}</span
    >
    <span v-if="lastPage && currentPage + 2 < lastPage">
      ...
    </span>
    <span
      v-if="lastPage && hasNextPage && currentPage + 1 < lastPage"
      @click="changePage(lastPage)"
      class="link"
      >{{ lastPage }}</span
    >
    <svg
      v-if="hasNextPage"
      @click="changePage(currentPage + 1)"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      class="link"
    >
      <path
        d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["currentPage", "hasNextPage", "changePage", "lastPage"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pagination .link {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #333333;
  padding: 8px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
}

.pagination svg {
  height: 32px;
}

.pagination .link.current {
  background: #fe3c00;
  color: #ffffff;
}
</style>
